.container {
  position: absolute;
  top: 20%;
  left: 50%;
  margin-left: -150px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 26px;
  padding-top: 10px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.25);
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.containerShow {
  opacity: 1;
  visibility: visible;
}

.lottieContainer {
  width: 300px;
}
