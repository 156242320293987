.proposalCard {
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 18px;
  margin-right: 0px;
  padding: 14px 16px 8px 31px;
  border-radius: 7px;
  background-color: #f4f4f4;
  overflow: hidden;
  transform: scale(1);
  transition: transform 200ms;
}

.proposalCard:active {
  transform: scale(0.98);
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}

.initials {
  padding: 2px 5px;
  background-color: #dce1ff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-self: center;
}

.warningContainer {
  margin-right: 8px;
  display: flex;
  align-self: center;
  margin-bottom: 2px; /* Needed due to triangle icon viewbox white space at top*/
}

.marginRightAuto {
  margin-right: auto;
}

.bottomMargin {
  margin-bottom: 4px;
}

.isActive {
  border-left: solid 4px #0d6efd;
  background-color: white;
}
