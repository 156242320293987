@value app: '../../pages/app.css';
@value mobile-breakpoint from app;

.thumbnail {
  flex: 1;
  position: relative;
  display: flex;
  max-width: 150px;
  height: 150px;
}

.image {
  border-radius: 10px;
}

.errorCard {
  width: 150px;
  height: 150px;
  background-color: whitesmoke;
}

@media (max-width: mobile-breakpoint) {
  .thumbnail {
    flex: auto;
    width: 150px;
    height: 150px;
  }
}
