.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 8px;
}

.sectionTitle {
  font-family: "cairo";
  font-weight: 600;
  font-size: 1.1em;
  margin-right: auto;
}

.downloadAllButton {
  display: flex;
  flex-direction: row;
  padding: 3px 10px;
  cursor: pointer;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.downloadAllText {
  font-family: "cairo";
  font-size: 0.8em;
  font-weight: 500;
  color: #c5c4c4;
  margin-left: 5px;
}
.fileListContainer {
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
