.title {
  margin-bottom: 20px;
}
.container {
  display: flex;
  flex-direction: column;
}

.textInput {
  width: 100%;
  min-width: 350px;
  margin-right: 15px;
  transition: all 0.5s ease-in-out;
}

.textButton {
  margin-top: 12px;
  display: inline-block;
  width: fit-content;
  text-decoration: underline;
  font-style: italic;
}

.errorMessage {
  color: red;
  font-style: italic;
  margin-top: 4px;
}
