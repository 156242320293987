.fileTitleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.errorIcon {
  margin-right: 12px;
  align-self: flex-end;
}

.errorText {
  color: #f93e3e;
  font-family: "cairo";
  font-size: 0.8em;
  margin-left: auto;
  font-style: italic;
  font-weight: 600;
}

.filesDescription {
  margin-bottom: 20px;
}

.fileFeed {
  overflow: scroll;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.addFileButton {
  font-size: 0.9em;
  padding: 3px 14px 3px 14px;
  border-radius: 14px;
  background-color: #f4f4f4;
  font-family: "cairo";
  display: inline-block;
}

.buttonContainer {
  margin-top: 22px;
  display: flex;
  flex-direction: row;
}

.submitButton {
  margin-right: 12px;
}
