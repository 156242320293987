.text {
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconPadding {
  padding: 3px 10px 3px 6px;
}
.defaultPadding {
  padding: 3px 10px 3px 12px;
  max-width: fit-content;
}

.icon {
  margin-right: 6px;
  display: flex;
  align-self: center;
}

.bold {
  font-weight: bold;
}

.regular {
  font-weight: 400;
}
