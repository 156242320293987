@value app: '../../pages/app.css';
@value mobile-breakpoint from app;

.proposalDetails {
  display: flex;
  background-color: white;
  border-radius: 5px;
  padding: 2em 2em 2em 2em;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.rowToColumn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.headerRow {
  flex: 1;
  flex-direction: column;
  padding-right: 12px;
}

.rowFlexStart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.proposalName {
  font-family: "cairo";
  font-weight: bold;
  font-size: 1.5em;
}

.createdDate {
  font-family: "cairo";
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 16px;
  margin-top: 8px;
}

.etherscanLogo {
  margin-right: 5px;
  display: flex;
}

.etherscanLink {
  margin-left: 4px;
  cursor: pointer;
  display: flex;
  border-radius: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  padding: 3px 3px;
  padding-right: 7px;
  background-color: white;
}

.openseaButton {
  margin-left: 4px;
}

.description {
  font-family: "cairo";
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 16px;
}

.defaultBottomMargin {
  margin-bottom: 16px;
  width: fit-content;
}

.placeholderText {
  font-size: 1em;
  font-weight: 300;
  position: absolute;
  top: 45%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transform: translateX(-50%);
  left: 50%;
}

.commitButton {
  padding: 4px 40px 4px 40px;
  border-radius: 20px;
  background-color: #645ef3;
  color: white;
  text-align: center;
  font-family: "cairo";
  font-size: 1em;
  margin-bottom: 4px;
}

.metadataEmptyView {
  margin-bottom: 6px;
}
.metadataContainer {
  overflow-x: scroll;
}

.image {
  border-radius: 10px;
}
/* font-family: "Courier New", Courier, monospace; */

@media (max-width: mobile-breakpoint) {
  .rowToColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .headerRow {
    flex: 1;
    flex-direction: column;
    width: 100%;
    padding-right: 0px;
  }

  .openseaButton {
    margin-left: 0px;
    margin-top: 8px;
  }

  .etherscanLink {
    margin-left: 0px;
    margin-top: 8px;
  }

  .description {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
