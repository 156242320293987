.openseaLink {
  cursor: pointer;
  display: flex;
  border-radius: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  padding: 3px 3px;
  padding-right: 7px;
  background: linear-gradient(to right, #2f6ab6, #9a6ab4);
}

.openseaLogo {
  margin-right: 5px;
  display: flex;
}

.openseaText {
  color: white;
}
