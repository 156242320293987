.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.title {
  margin-right: 12px;
}
.marginRightAuto {
  margin-right: auto;
  padding: 1px;
}

.body {
  font-family: "cairo";
  font-weight: 400;
  font-size: 0.9em;
  text-wrap: wrap;
  word-break: break-all;
}
