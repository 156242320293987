.input {
  font-family: "cairo";
  border-radius: 4px;
  border: solid 1px #d3d3d3;
  padding: 6px 10px 6px 10px;
  width: 100%;
}

.multiline {
  height: 120px;
  max-height: 250px;
  min-height: 34px;
}
