@value app: '../../pages/app.css';
@value mobile-breakpoint from app;

.navBar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: #f9fafd;
  z-index: 10;
}

.container {
  flex: 1;
  display: flex;
  padding: 12px 18px 12px 18px;
  flex: 1;
  align-items: center;
  top: 0;
}

.domainName {
  font-family: "cairo";
  font-size: 1.2em;
  font-weight: 600;
  margin-left: 8px;
  cursor: pointer;
  margin-right: auto;
}

.proposals {
  font-family: "cairo";
  font-size: 1em;
  font-weight: 500;
  margin-left: 30px;
  cursor: pointer;
  margin-right: 32px;
  color: #222222;
}

.navItem {
  border: none;
  background: transparent;
  font-family: "cairo";
  font-size: 1em;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
  color: #222222;
}

.navItem:hover {
  opacity: 0.6;
}

.proposals:hover {
  opacity: 0.6;
}

.profileContainer {
  display: flex;
  align-self: center;
  margin-right: 26px;
  cursor: pointer;
}

.line {
  border-bottom: solid 1px #e1e1e1;
  margin-left: 18px;
  margin-right: 18px;
}

@media (max-width: mobile-breakpoint) {
  .profileContainer {
    margin-right: 0px;
  }
}
