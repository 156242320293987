.fileCard {
  margin: 10px 10px 25px 0;
  padding: 8px 8px 6px 8px;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(134, 134, 134, 0.3);
  width: 150px;
  overflow: hidden;
}

.cardTopRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.fileIcon {
  height: 40px;
  margin-left: 4px;
  margin-right: auto;
}
.fileName {
  font-family: "cairo";
  font-size: 0.9em;
  font-weight: 600;
  margin-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.button {
  cursor: pointer;
}

.button:active {
  opacity: 0.4;
}
