.container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.icon {
  margin-right: 6px;
  align-self: center;
  display: flex;
}

.warningIcon {
  margin-bottom: 2px; /* Needed due to triangle icon viewbox white space at top*/
}

.errorText {
  font-style: italic;
}
