.base {
  padding: 4px 36px 4px 36px;
  text-align: center;
  border-radius: 8px;
}

.wide {
  padding: 4px 36px 4px 36px;
}

.narrow {
  padding: 4px 18px 4px 18px;
}
