@value app: '../../pages/app.css';
@value mobile-breakpoint from app;

.container {
}

.metadataTitle {
  width: fit-content;
}
.metadataEmptyView {
  margin-bottom: 6px;
}
.metadataContainer {
  overflow-x: scroll;
}

@media (max-width: mobile-breakpoint) {
  .container {
    overflow-x: hidden;
    width: 100%;
  }

  .innerContainer {
    overflow-x: scroll;
  }
}
