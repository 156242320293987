.container {
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 32px 0 rgba(119, 117, 117, 0.25);
  background-color: #fff;
}

.enabled {
  border-left: solid 4px #0d6efd;
}

.disabled {
  border-left: solid 4px #cacaca;
}

.title {
  margin-bottom: 12px;
}

.errorText {
  font-style: italic;
}

.errorRow {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.errorIcon {
  margin-right: 6px;
  align-self: center;
  display: flex;
}
